<template>
  <h2>推播及公告日程表</h2>

  <HelloWorld></HelloWorld>
</template>

<style scoped>
table {
  text-align: center;
  word-break: keep-all;
}

table th,
table td {
  vertical-align: middle;
}

tr.delete {
  background-color: #f8d7da;
}

#searchTypeSelect {
  border-color: #ced4da;
  color: #6c757d;
  padding: 0 10px;
}

.id_show {
  width: 100px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  /* background-color: #6c757d;
  color: #fff;
  padding: 5px; */
}
</style>

<script></script>

<style scoped></style>
